import { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import './App.css';
import logo from './logo.png'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import request from './getHttp'
import card from './pay-system.png'
import yoomoney from './yoomoney.png'
import qiwi from './qiwi.png'
const theme = createTheme({
  palette: {
   mode: 'dark'
  },
});

//410019353115807

const KEKSIK = 'https://vk.com/app6887721_-189770943#donate_'

function App() {
  const [id, setId] = useState(null)
  const [money, setMoney] = useState(null)
  const [product, setProduct] = useState(0)
  const [fields, setFields] = useState([])
  const [user, setUSer] = useState(false)

  const getLinkKeksik = () => {
    window.location.href = KEKSIK + money
  }

useEffect(() => {
    if (window.location.hash.length > 0) {
      setId(window.location.hash.slice(1))
    }
}, [])

useEffect(() => {
  console.log(money, product);
}, [money, product])

const getProducts = async () => {
    try {
      let req = await request('get_shop_info', 'POST', {id})
      if (req.error) {
        setFields('Пользователь не найден!')
      } else {

        let body = []

        for (let key in req.fields) {
          body.push({ label: key, price: 0, product: 0 })
          req.fields[key].forEach(prod => {
            let tag = ''
            if (key === 'Наборы FC') {
              tag = '≈'
            }
            body.push({ label: `${tag}${prod.tag} - ${prod.price}р.`, price: prod.price, product: prod.product })
          })
        }
        setFields(
        <Autocomplete
          style={{ width: '94%', margin: '0 auto', color: 'white'}}
          disablePortal
          placeholder='Выберите продукт'
          id="combo-box-demo"
          onChange={(event, newValue) => {
            setMoney(newValue.price);
            setProduct(newValue.product);
          }}
          options={body}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField  {...params} label="Выберите донат" />}
        />
        )


        setUSer(true)
      }

    } catch (e) {
      console.log(e);
    }
}


  return (
    <div className="App">
      <div className='body'>
        <ThemeProvider theme={theme}>
        <img src={logo} alt='logo' width={150} height={150} className='App-logo'/>
        <div className='donate-window'>
          <div className='donate-header'>При покупке доната КОМИССИИ НЕТ!</div>
          <div className='donate-header-2'>ПОКУПКА ДОНАТА</div>
          <div className='donate-header-input-header'>Ваш ID:</div>

          <input disabled={user} type='number' value={id} className='donate-header-input' placeholder='Игровой ID у бота' id='1-input' onChange={() => {
            setId(document.getElementById('1-input').value)
          }}/>

          {fields}

         {user ?
         <div>

          <input type="submit" disabled={id && money > 10 && product && product < 3 ? false : true}  className="donate-button-keksik" value='Оплатить' onClick={getLinkKeksik}/>

          <form 
            method="POST" 
            action="https://yoomoney.ru/quickpay/confirm.xml">    
              <input type="hidden" name="receiver" value={4100118506084210}/>    
              <input type="hidden" name="formcomment" value="XOP EHOTOB"/>    
              <input type="hidden" name="short-dest" value="XOP EHOTOB"/>    
              <input type="hidden" name="label" value={`1${product}${id}`}/>    
              <input type="hidden" name="quickpay-form" value="donate"/>    
              <input type="hidden" name="targets" value={`ID: ${id}`}/>    
              <input type="hidden" name="sum" value={money} data-type="number"/>    
              <input type="hidden" name="comment" value={`Донат от пользователя - ${id}`}/>     
              {<center><input disabled={id && money && product ? false : true} type="submit" className="donate-button" value='Ю Money (-15%)'/></center>}
          </form>

          <p className='PayFooter'>При донате Ю Money вы получите на 15% меньше коинов</p>
          </div>
           : <div className="donate-button-info" onClick={getProducts}>Показать продукты</div>}
        </div>

        <div className='donate-window'>
          <div id="vk_groups"></div>
        </div>
        
        <div className='info'>
          <a href='https://footballcoin.ru/agreement.html' target='_blank' rel="noreferrer" >Соглашение</a>
          <a href='https://footballcoin.ru/privacy.html' target='_blank' rel="noreferrer" >Конфиденциальность</a>
        </div>

        <div className='card-image'><img src={card} alt='card'/></div>
        <div className='card-image-wallets'><img src={yoomoney} alt='card'/> <img src={qiwi} alt='card'/></div>

        </ThemeProvider>

        <div className='footer'>Дмитриев Дмитрий Николаевич</div>
        <div className='footer'>ИНН 774349218835</div>
        <div className='footer'>support@footballcoin.ru</div>
      </div>
    </div>
  );
}

export default App;